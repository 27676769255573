var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("b-modal", {
        ref: "modal-create-report",
        attrs: {
          id: "modal-create-report",
          "no-close-on-backdrop": "",
          "footer-class": "flex-nowrap",
          centered: ""
        },
        scopedSlots: _vm._u([
          {
            key: "modal-header",
            fn: function() {
              return [
                _c("h3", [_vm._v(_vm._s(_vm.$t("modals.createReport.title")))])
              ]
            },
            proxy: true
          },
          {
            key: "default",
            fn: function() {
              return [
                _c(
                  "div",
                  { staticClass: "mt-4 mx-5 mb-3" },
                  [
                    _c("b-form-input", {
                      attrs: {
                        placeholder: _vm.$t("modals.createReport.name"),
                        autofocus: "",
                        debounce: "500"
                      },
                      on: {
                        update: _vm.setName,
                        keyup: function($event) {
                          if (
                            !$event.type.indexOf("key") &&
                            _vm._k(
                              $event.keyCode,
                              "enter",
                              13,
                              $event.key,
                              "Enter"
                            )
                          )
                            return null
                          return _vm.createReport.apply(null, arguments)
                        }
                      },
                      model: {
                        value: _vm.reportName,
                        callback: function($$v) {
                          _vm.reportName = $$v
                        },
                        expression: "reportName"
                      }
                    })
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "mx-5 mb-4" },
                  [
                    _c("b-form-input", {
                      attrs: {
                        placeholder: _vm.$t("modals.createReport.description"),
                        debounce: "500"
                      },
                      on: {
                        update: _vm.setDescription,
                        keyup: function($event) {
                          if (
                            !$event.type.indexOf("key") &&
                            _vm._k(
                              $event.keyCode,
                              "enter",
                              13,
                              $event.key,
                              "Enter"
                            )
                          )
                            return null
                          return _vm.createReport.apply(null, arguments)
                        }
                      },
                      model: {
                        value: _vm.reportDescription,
                        callback: function($$v) {
                          _vm.reportDescription = $$v
                        },
                        expression: "reportDescription"
                      }
                    })
                  ],
                  1
                )
              ]
            },
            proxy: true
          },
          {
            key: "modal-footer",
            fn: function() {
              return [
                _c(
                  "b-button",
                  {
                    staticClass: "p-3 border-right-light-grey",
                    attrs: { block: "" },
                    on: {
                      click: function($event) {
                        return _vm.$emit("cancel")
                      }
                    }
                  },
                  [_vm._v(" " + _vm._s(_vm.$t("buttons.cancel")) + " ")]
                ),
                _c(
                  "b-button",
                  {
                    staticClass: "p-3",
                    attrs: {
                      disabled: !_vm.isReportNameSet,
                      variant: "primary",
                      block: ""
                    },
                    on: { click: _vm.createReport }
                  },
                  [
                    _vm._v(
                      " " + _vm._s(_vm.$t("modals.createReport.validate")) + " "
                    )
                  ]
                )
              ]
            },
            proxy: true
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }